import React from "react"

import { FormattedMessage } from "react-intl"
import { handOverDate } from "pages/Shared/Columns/SpecificColumns/HandOverDate"
import { TerminationNames } from "../../../types/TerminationTypes"
import { Column } from "../interfaces"
import { BooleanCell, SplittedIconCell, TerminationCell } from "../../../components/DataGrid/cellRenderers"
import { SelectHeaderCustomValues, SelectYesNot } from "../../../components/DataGrid/filterHeaders"
import { exportBoolean } from "../../../components/DataGrid/export"
import { attachmentsColumn } from "../../../components/DataGrid/commonColumns"
import {
  articleAmount,
  articleName,
  articleNumber,
  articleGroupName,
  createdByFullName,
  createdOn,
  endDate,
  locationName,
  orderDate,
  orderPositionNumber,
  ownedByFullName,
  pending,
  planAnyway,
  processCustomerName,
  processCustomerNumber,
  processName,
  processNumber,
  startDate,
  statusId,
  terminationDate,
} from "./columns"

const ColumnsConfigOrderPositions: Column[] = [
  processNumber,
  processName,
  ownedByFullName,
  locationName,
  orderPositionNumber,
  orderDate,
  processCustomerNumber,
  processCustomerName,
  articleNumber,
  articleName,
  articleAmount,
  articleGroupName,
  statusId,
  pending,
  {
    id: "comment",
    translationId: "term.comment",
    accessor: "comment",
    Cell: BooleanCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.comment" />,
    width: 40,
    showTooltip: false,
  },
  {
    ...attachmentsColumn,
    id: "hasAttachments",
    translationId: "item.hasAttachments",
    accessor: "hasAttachments",
    Filter: <div />,
    width: 40,
    showTooltip: false,
  },
  {
    id: "isSplit",
    translationId: "term.hadSplit",
    accessor: (row: { splitted: boolean; isSplit: boolean }): { splitted: boolean; isSplit: boolean } => ({
      splitted: row.splitted,
      isSplit: row.isSplit,
    }),
    Cell: SplittedIconCell,
    Filter: SelectHeaderCustomValues([{ value: true, label: "term.hadSplit" }]),
    Header: <FormattedMessage id="term.hadSplit" />,
  },
  planAnyway,
  {
    id: "termination.terminationName",
    translationId: "term.termination",
    accessor: "terminationId",
    Cell: TerminationCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.termination" />,
    export: ({ value }: { value: string }) => ({ id: `type.termination.${TerminationNames[value]}` }),
  },
  terminationDate,
  handOverDate,
  startDate,
  endDate,
  createdOn,
  createdByFullName,
  {
    id: "createdByImport",
    translationId: "term.imported",
    accessor: "createdByImport",
    Header: <FormattedMessage id="term.imported" />,
    Cell: BooleanCell,
    Filter: SelectYesNot,
    export: exportBoolean,
    showTooltip: false,
  },
]

export default ColumnsConfigOrderPositions
