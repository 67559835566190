import Moment from "moment"
import { extendMoment } from "moment-range"

import {
  clone,
  cloneDeep,
  filter,
  fromPairs,
  forEach,
  groupBy,
  isEmpty,
  keyBy,
  last,
  map,
  mapValues,
  orderBy,
  partition,
  pick,
  reduce,
  some,
  sortBy,
  uniq,
  uniqBy,
  values,
  get,
  includes,
  first,
  tail,
  reverse,
  every,
  flatMap,
  find,
  omitBy,
} from "lodash"

import { Availability } from "../../../utils/timeline/availability"

import { forcedTimezone } from "../../../utils/timeline/forcedTimezone"
import TimelineItemTypes from "../../../types/TimelineItemTypes"
import errors from "../../../utils/errors"
import { getResourceConflicts } from "./conflicts"
import { cloneStore, updateConflictsForResources } from "./updateHelpers"

export const StatusOfMovingTypes = {
  frozenZone: "frozenZone",
}

const moment = extendMoment(Moment)
const operationHasNoCapacity = "noCapacity"

export const checkForError = state => {
  const { operationsResourcesSuggestions } = state
  if (!isEmpty(operationsResourcesSuggestions)) {
    throw new errors.UserError("pages.timeline.parallelResources.resourceConflictWithResources", null, {
      operationsResourcesSuggestions,
    })
  }
}

export const checkErrorIsEmpty = (err, state, movedItem, ActionType) => {
  if (
    err instanceof errors.UserError &&
    err.messageId === "pages.timeline.parallelResources.resourceConflictWithResources"
  ) {
    const operationsResourcesSuggestions = get(err, "values.operationsResourcesSuggestions", {})
    if (isEmpty(operationsResourcesSuggestions)) {
      throw new errors.UserError("pages.timeline.parallelResources.resourceConflict", null, {
        operationsResourcesSuggestions,
      })
    }
    return {
      ...state,
      operationsResourcesSuggestions,
      ActionTypeToWait: ActionType,
      movedItem,
    }
  }
  throw err
}

export const getAffectedResources = (projectId, oldState, newState) => {
  const originalOperations = getProjectOperations(projectId, oldState)
  const updatedOperations = keyBy(getProjectOperations(projectId, newState), "operationId")
  return uniq(
    map(
      filter(
        originalOperations,
        op =>
          !updatedOperations[op.operationId] ||
          op.status !== updatedOperations[op.operationId].status ||
          op.startDate !== updatedOperations[op.operationId].startDate ||
          op.endDate !== updatedOperations[op.operationId].endDate
      ),
      "resource"
    )
  )
}

export const getAffectedResourcesForAllSelectedOperation = ({ selectedOperations, state, nextState }) =>
  flatMap(selectedOperations, ({ id }) => {
    const { projectId } = nextState.operations[id]
    return getAffectedResources(projectId, state, nextState)
  })

export function isResourceFree(resource, allOperations, operation, state, initial = null) {
  if (resource.allowMultipleReservation && !get(resource, "maxMultipleReservations")) {
    return true
  }

  let clonedOperation = cloneDeep(operation)
  clonedOperation.resource = resource.resourceId

  if (initial) {
    // case: parallel operation shrinked but mainOperation bigger, you must to check here with full wide of operation
    clonedOperation.startDate = initial.startDate
    clonedOperation.endDate = initial.endDate
  }

  // on the next possible resource the parallel operation can have smaller planTime, cause less conflicts
  clonedOperation = updateOperation(state, clonedOperation, clonedOperation.startDate, clonedOperation.endDate)
  const operationsOnThisResource = filter(
    allOperations,
    op =>
      op.operationId !== clonedOperation.operationId &&
      op.resource === clonedOperation.resource &&
      new Date(op.startDate) < new Date(clonedOperation.endDate) &&
      new Date(op.endDate) > new Date(clonedOperation.startDate)
  )
  const conflicts = getResourceConflicts(
    clonedOperation.resource,
    [...operationsOnThisResource, clonedOperation],
    resource
  )

  if (conflicts.length) return false

  return clonedOperation.planTime > 0
}

export function getAvailability(res, state, { startDate, endDate }) {
  const { availabilityPlans, resourceGaps, resourceId: resId } = res

  const capacityPlans = map(availabilityPlans, ({ availabilityPlan, ...others }) => ({
    ...state.capacityPlans[availabilityPlan],
    ...others,
  }))
  const configuration = {
    capacityPlans,
    gaps: resourceGaps,
    holidays: state.holidays,
  }

  const availability = new Availability(configuration, new Date(startDate), forcedTimezone)
  const gaps = availability.read(new Date(endDate))
  if (gaps.length === 0) {
    return [resId, 0] // no gaps, full free
  }
  const isEndDateBefore = end => moment(endDate).isBefore(end)
  const isStartDateAfter = start => moment(startDate).isAfter(start)
  const gapDurations = gaps.map(({ start, end }) =>
    moment(isEndDateBefore(end) ? endDate : end).diff(isStartDateAfter(start) ? startDate : start)
  )
  return [resId, !sumUpGaps(gapDurations) ? 0 : sumUpGaps(gapDurations)]
}

export function getFreeResource(currentResource, operation, mainOperation, state, allOperations) {
  const { resourceId } = currentResource
  const { resourceGroup: resourceGroupId } = operation
  const allResourceFromGroup = filter(
    state.resources,
    res => includes(res.resourceGroups, resourceGroupId) && res.resourceId !== resourceId && !res.archived
  )
  // reset operation start and end date
  const initial = {
    startDate: mainOperation.startDate,
    endDate: mainOperation.endDate,
  }
  let freeResource = filter(allResourceFromGroup, res => isResourceFree(res, allOperations, operation, state, initial))

  // sorting for availabilities
  const availabilitiesOfFreeResources = fromPairs(map(freeResource, res => getAvailability(res, state, mainOperation)))
  // test if this not covered over updateOperation
  // exclude resource with no capacity
  const totalDuration = moment(mainOperation.endDate).diff(mainOperation.startDate)
  freeResource = filter(
    freeResource,
    res =>
      availabilitiesOfFreeResources[res.resourceId] < totalDuration ||
      availabilitiesOfFreeResources[res.resourceId] === 0
  )

  return sortBy(freeResource, [res => availabilitiesOfFreeResources[res.resourceId], "resourceId"])
}

export function sortMainOperations(mainOperations, timeDifference) {
  return orderBy(mainOperations, ["position"], [timeDifference > 0 ? "desc" : "asc"])
}

export function getGroupedDependentOp(dependentOperations) {
  return groupBy(dependentOperations, "mainOperationId")
}

// parallel operations
export function updateDependent(keyedDependentOperations, movedOperation, nextState) {
  const affectedResources = []
  const { operations, operationsResourcesSuggestions: operationsResources } = nextState
  const currentDependent = keyedDependentOperations[movedOperation.operationId] || []
  const movedKeyedDependent = keyBy(
    map(currentDependent, dop => updateOperation(nextState, dop, movedOperation.startDate, movedOperation.endDate)),
    "operationId"
  )
  let operationsWithMoved = cloneDeep(nextState.operations)
  operationsWithMoved = mapValues(operationsWithMoved, (val, key) =>
    movedKeyedDependent[key] ? movedKeyedDependent[key] : val
  )
  // checking and search for free resources
  forEach(currentDependent, dop => {
    const currentOp = movedKeyedDependent[dop.operationId]
    const resource = nextState.resources[currentOp.resource]
    affectedResources.push(currentOp.resource)

    const { conflicts, errorCode } = validateDependentOperation(resource, operationsWithMoved, currentOp)
    const opInAGap = errorCode === operationHasNoCapacity
    const shallApplyConflict = !!currentOp.suggestResource && currentOp.resource === currentOp.suggestResource // user already decide to use the old resource with conflicts

    if ((shallApplyConflict || !conflicts.length) && !opInAGap) {
      operations[dop.operationId] = currentOp
      return
    }

    if (opInAGap) {
      currentOp.resource = null
    }

    const freeResources = getFreeResource(resource, currentOp, movedOperation, nextState, operationsWithMoved)
    if (freeResources.length) {
      // eslint-disable-next-line no-param-reassign
      operationsResources[currentOp.operationId] = {
        freeResources,
        operationResource: resource,
        operation: currentOp,
        movedMainOperation: movedOperation,
        skipOperationDefaultResource: opInAGap,
      }
      return
    }

    if (!freeResources.length && opInAGap) {
      throw new errors.UserError("pages.timeline.parallelResources.resourceConflict")
    }

    operations[dop.operationId] = currentOp
  })
  updateConflictsForResources(nextState, affectedResources)
}

export const excludeAttendantOperations = (siblings, state) =>
  !state
    ? omitBy(siblings, op => !!op.attendantOperation)
    : filter(siblings, op => !state.operations[op.id]?.attendantOperation)

/* Move siblings of an operation but only to prevent overlaps */
export const adjustOperationSiblings = (
  nextState,
  operationBeforeMove,
  operationAfterMove,
  freeMovable,
  attendantOperations = {}
) => {
  const { operationId, position, productionOrderId } = operationBeforeMove
  const { operations } = nextState
  // operationsResourcesSuggestions - for parallel operations
  let siblings = filter(operations, op => op.productionOrderId === productionOrderId && op.operationId !== operationId)
  siblings = excludeAttendantOperations(siblings)

  // only for parallel operations
  const [dependentOperations, mainOperations] = partition(siblings, "mainOperationId")
  const keyedDependentOperations = groupBy(dependentOperations, "mainOperationId")
  // parallel of touched op (not sibling!!!)
  updateDependent(keyedDependentOperations, operationAfterMove, nextState)

  if (freeMovable) {
    checkForError(nextState)
    return
  }
  const adjustNexts = new Date(operationAfterMove.endDate) - new Date(operationBeforeMove.endDate) > 0 // only adjust next operations if the change was towards future
  const adjustPrevs = new Date(operationAfterMove.startDate) - new Date(operationBeforeMove.startDate) < 0 // only adjust previous operations if the change was towards past

  const operationPosition = parseInt(position, 10)
  const [predecessors, successors] = partition(mainOperations, op => parseInt(op.position, 10) < operationPosition) // partition avoids two filtering operations over the same amount of items
  const nexts = orderBy(successors, [op => new Date(op.startDate), "mainOperationId"], ["asc", "asc"])
  const prevs = orderBy(predecessors, [op => new Date(op.startDate), "mainOperationId"], ["desc", "asc"])

  if (adjustNexts) {
    reduce(
      nexts,
      (prevOp, op) => {
        if (!prevOp) {
          return false
        }
        const bufferTimeInMs = (prevOp.bufferTime || 0) * 1000
        const prevEndDate = Date.parse(prevOp.endDate) + bufferTimeInMs
        const startDate = Date.parse(op.startDate)

        if (prevEndDate > startDate) {
          // need to move the sibling to avoid overlap
          const { movedOperation } = moveOperation(nextState, op.operationId, prevEndDate - startDate)
          const changedAttendantOperations = changeAttendantOperations(nextState, movedOperation, attendantOperations)
          writeAttendantOperations(operations, changedAttendantOperations)

          operations[op.operationId] = movedOperation
          updateDependent(keyedDependentOperations, movedOperation, nextState) // parallel of pref op
          return operations[op.operationId]
        }

        // If this operation hasn't moved, skip all those after it
        return false
      },
      operationAfterMove
    )
  }

  if (adjustPrevs) {
    reduce(
      prevs,
      (prevOp, op) => {
        if (!prevOp) {
          return false
        }
        const bufferTimeInMs = (op.bufferTime || 0) * 1000
        const prevStartDate = Date.parse(prevOp.startDate) - bufferTimeInMs
        const endDate = Date.parse(op.endDate)

        if (endDate > prevStartDate) {
          // need to move the sibling to avoid overlap
          const { movedOperation } = moveOperation(nextState, op.operationId, (endDate - prevStartDate) * -1)
          const changedAttendantOperations = changeAttendantOperations(nextState, movedOperation, attendantOperations)
          writeAttendantOperations(operations, changedAttendantOperations)
          operations[op.operationId] = movedOperation
          updateDependent(keyedDependentOperations, movedOperation, nextState) // parallel of next op
          return operations[op.operationId]
        }

        // If this operation hasn't moved, skip all those after it
        return false
      },
      operationAfterMove
    )
  }
  checkForError(nextState)
}

export const adjustSelectedOperations = (nextState, selectedOperationsInput, selectedOperationsSpan, movedForward) => {
  let selectedOperations = selectedOperationsInput
  let direction = 1
  if (!movedForward) {
    selectedOperations = reverse(selectedOperationsInput)
    direction = -1
  }

  reduce(
    tail(selectedOperations),
    (opPrev, opNext) => {
      let span, timeDifference, newSpan
      const { id: nextId } = opNext
      const { id: prevId } = opPrev
      if (movedForward) {
        span = selectedOperationsSpan[`${prevId}-${nextId}`] || 0
        newSpan = new Date(nextState.operations[nextId].startDate) - new Date(nextState.operations[prevId].endDate)
      } else {
        span = selectedOperationsSpan[`${nextId}-${prevId}`] || 0
        newSpan = new Date(nextState.operations[prevId].startDate) - new Date(nextState.operations[nextId].endDate)
      }

      if (newSpan < span) {
        timeDifference = (span - newSpan) * direction
        const { id } = opNext
        const operation = clone(nextState.operations[id])
        const attendantOperations = getAttendantOperations(nextState, operation.productionOrderId)
        const { movedOperation } = moveOperation(nextState, id, timeDifference)
        const changedAttendantOperations = changeAttendantOperations(nextState, movedOperation, attendantOperations)
        writeAttendantOperations(nextState.operations, changedAttendantOperations)
        nextState.operations[id] = movedOperation
        adjustOperationSiblings(nextState, operation, movedOperation, false, attendantOperations)
      }

      return opNext
    },
    first(selectedOperations)
  )
}
/**
 * selectedOperations: {id, type} - old format selected items
 * movedItemsKeyed - selected items, timeline.js ITEM data
 * operations: operation from redux data (API)
 * -----
 * we use selectedOperations as a base
 * because all of functions wrote with this selectedOperations
 * */
const hasResourceChangedInOneOfOperations = (selectedOperationIds, movedItemsKeyed, operations) =>
  some(selectedOperationIds, id => movedItemsKeyed[id]?.group !== operations[id]?.resource)

const isAllTargetResourcesInCurrentGroups = ({ selectedOperationIds, movedItemsKeyed, operations, resources }) =>
  every(selectedOperationIds, id => {
    const targetResource = movedItemsKeyed[id]?.group
    const { resourceGroup: resourceGroupId } = operations[id]
    return includes(resources[targetResource].resourceGroups, resourceGroupId)
  })
const isAllTargetResourcesNotAttendant = ({ selectedOperationIds, movedItemsKeyed, resources }) =>
  every(selectedOperationIds, id => {
    const targetResource = movedItemsKeyed[id]?.group
    return !resources[targetResource].isAttendantResource
  })
const getAllTargetAndActualResources = (selectedOperationIds, movedItemsKeyed, operations) =>
  flatMap(selectedOperationIds, id => {
    const targetResource = movedItemsKeyed[id]?.group
    const { resource } = operations[id]
    return [targetResource, resource]
  })

export const checkResourceChanged = (
  state,
  nextState,
  selectedOperations,
  isResourceTimeline,
  moveResourcesAcrossGroups,
  movedItems,
  forceResourceChangeAttendantOperation = false
) => {
  /**
   * Check if operation was moved to another resource.
   * Only allow moving operations between resources of the same resourceGroup.
   * Reject the drop, if it´s dropped somewhere else.
   */
  const movedItemsKeyed = keyBy(movedItems, "itemId")
  const selectedOperationIds = map(selectedOperations, "id")
  let affectedResources = []
  const resourceChanged = hasResourceChangedInOneOfOperations(selectedOperationIds, movedItemsKeyed, state.operations)

  if (isResourceTimeline && resourceChanged) {
    const isNewResourcesInResourceGroups = isAllTargetResourcesInCurrentGroups({
      selectedOperationIds,
      movedItemsKeyed,
      resources: state.resources,
      operations: state.operations,
    })
    if (!moveResourcesAcrossGroups && !isNewResourcesInResourceGroups) {
      throw new errors.UserError("pages.timeline.cantChangeResource.badGroup")
    }
    // eslint-disable-next-line no-unused-vars
    const isNewTargetResourcesNotAttendant = isAllTargetResourcesNotAttendant({
      selectedOperationIds,
      movedItemsKeyed,
      resources: state.resources,
    })
    if (!forceResourceChangeAttendantOperation && !isNewTargetResourcesNotAttendant) {
      throw new errors.UserError("pages.timeline.cantChangeResource.resourceAttendant")
    }
    affectedResources = getAllTargetAndActualResources(selectedOperationIds, movedItemsKeyed, state.operations)

    forEach(selectedOperationIds, id => {
      let newResourceGroup = null
      const targetResource = movedItemsKeyed[id]?.group
      const { resourceGroup: resourceGroupId } = state.operations[id]
      if (!includes(state.resources[targetResource].resourceGroups, resourceGroupId)) {
        // eslint-disable-next-line prefer-destructuring
        newResourceGroup = state.resources[targetResource].resourceGroups[0]
      }

      const operation = clone(nextState.operations[id])
      operation.resource = targetResource
      operation.resourceGroup = newResourceGroup || operation.resourceGroup
      nextState.operations[id] = operation
    })
  }
  return [affectedResources, nextState]
}

export const getSelectedCompactOperations = state => {
  const selectedOperations = filter(state.selection, { type: TimelineItemTypes.Operation })
  // Order selected operations by startDate
  let orderedSelectedOperations
  if (!isEmpty(state.operations)) {
    orderedSelectedOperations = orderBy(selectedOperations, ({ id }) => new Date(state.operations[id].startDate), [
      "asc",
    ])
  }
  return orderedSelectedOperations
}

export const getSelectedOperations = nextState => {
  const selectedOperations = filter(nextState.selection, { type: TimelineItemTypes.Operation })
  const itemsToMove = orderBy(
    pick(nextState.operations, map(selectedOperations, "id")),
    [item => new Date(item.endDate)],
    ["asc"]
  )
  return itemsToMove
}

const sumUpGaps = gaps => (gaps.length >= 1 ? gaps.reduce((acc, curr) => acc + curr.valueOf(), 0) : 0)

const convertToRanges = gaps => gaps.map(({ start, end }) => moment.range(start, end))

/**
 * Input:
 * - clone of state
 * - operation from state, but with new ResourceId
 * */
export const moveOperation = (
  state,
  operationId,
  difference,
  lengthDifference = 0,
  previousConfiguration = {},
  invert = false, // This parameter tells us if we should invert the calculations, ie if we move backwards, still calculate the times from the start date.
  // It is useful for closing the gaps
  skipFrozenZoneChecking = false
) => {
  const operation = state.operations[operationId]

  const {
    startDate: oldStart,
    endDate: oldEnd,
    planTime: hasPlanTime,
    resource: resourceId,
    attendantOperation,
  } = operation
  const oldStartDate = new Date(oldStart)
  const oldEndDate = new Date(oldEnd)
  if (attendantOperation) return { movedOperation: operation }

  const resource = state.resources[resourceId]
  const { frozenTime, availabilityPlans, resourceGaps } = resource

  const capacityPlans = map(availabilityPlans, ({ availabilityPlan, ...others }) => ({
    ...state.capacityPlans[availabilityPlan],
    ...others,
  }))

  const configuration = {
    capacityPlans,
    gaps: resourceGaps,
    holidays: state.holidays,
  }

  const oldGapStream = new Availability(
    {
      ...configuration,
      ...previousConfiguration,
    },
    oldStartDate,
    forcedTimezone
  )

  const oldGaps = convertToRanges(oldGapStream.read(oldEndDate))

  let planTime
  if (!hasPlanTime) {
    // Recalculate planTime as fallback
    planTime = oldEndDate - oldStartDate - sumUpGaps(oldGaps)
  } else {
    planTime = hasPlanTime * 1000
  }

  // Which direction
  let movedForward = difference > 0
  if (invert) movedForward = !movedForward

  let newStartDate, newEndDate
  if (movedForward) {
    newStartDate = new Date(oldStartDate.getTime() + difference)
    newEndDate = new Date(newStartDate.getTime() + planTime)
  } else {
    newEndDate = new Date(oldEndDate.getTime() + difference)
    newStartDate = new Date(newEndDate.getTime() - planTime)
  }

  const newGapStream = new Availability(configuration, oldStartDate, forcedTimezone)
  let newGaps = convertToRanges(newGapStream.reset(newStartDate).read(newEndDate))

  if (movedForward) {
    // Move start forward as long as it is inside a gap
    while (newGaps.length > 0 && newGaps[0].contains(newStartDate, { excludeEnd: true })) {
      const gap = newGaps.shift()
      newStartDate = new Date(gap.end.valueOf()) // Move it outside the gap
      newEndDate = new Date(newStartDate.getTime() + planTime)
      newGaps = convertToRanges(newGapStream.reset(newStartDate).read(newEndDate)) // Maybe the updated region start in a gap again
    }

    // Move the end forward until it matches the plantime
    let newPlantime = newEndDate.getTime() - newStartDate.getTime()
    let newGapsDuration = sumUpGaps(newGaps)
    while (newPlantime - newGapsDuration !== planTime) {
      newEndDate = new Date(newStartDate.getTime() + planTime + newGapsDuration)
      newGaps = convertToRanges(newGapStream.reset(newStartDate).read(newEndDate))
      newGapsDuration = sumUpGaps(newGaps)
      newPlantime = newEndDate.getTime() - newStartDate.getTime()
    }
  } else {
    // Move end backwards as long as it is inside a gap
    while (newGaps.length > 0 && newGaps[newGaps.length - 1].contains(newEndDate, { excludeStart: true })) {
      const gap = newGaps.pop()
      newEndDate = new Date(gap.start.valueOf()) // Move it outside the gap
      newStartDate = new Date(newEndDate.getTime() - planTime)
      newGaps = convertToRanges(newGapStream.reset(newStartDate).read(newEndDate))
    }

    // Move the start backwards until it matches the plantime
    let newPlantime = newEndDate.getTime() - newStartDate.getTime()
    let newGapsDuration = sumUpGaps(newGaps)
    while (newPlantime - newGapsDuration !== planTime) {
      newStartDate = new Date(newEndDate.getTime() - planTime - newGapsDuration)
      newGaps = convertToRanges(newGapStream.reset(newStartDate).read(newEndDate))
      newGapsDuration = sumUpGaps(newGaps)
      newPlantime = newEndDate.getTime() - newStartDate.getTime()
    }
  }

  // If the operation is within a frozenZone, we do not move it
  const frozenZoneEnd = new Date(new Date().valueOf() + lengthDifference + frozenTime * 1000)
  const frozenZoneStart = new Date()
  if (
    frozenTime &&
    ((frozenZoneEnd > oldStartDate && frozenZoneStart < oldEndDate) ||
      (frozenZoneEnd > newStartDate && frozenZoneStart < newEndDate))
  ) {
    if (!skipFrozenZoneChecking) return { movedOperation: operation, statusOfMoving: StatusOfMovingTypes.frozenZone }
  }

  const movedOperation = {
    ...operation,
    planTime: Math.floor(planTime / 1000),
    startDate: moment(newStartDate).format(),
    endDate: moment(newEndDate).format(),
  }

  return { movedOperation }
}

export const updateOperation = (nextState, operation, newStart, newEnd) => {
  let newStartDate = new Date(newStart)
  let newEndDate = new Date(newEnd)
  let newRange = moment.range(newStartDate, newEndDate)

  const { resource: resourceId } = operation

  const resource = nextState.resources[resourceId]
  const { frozenTime, availabilityPlans } = resource

  /* If operation is within a frozenZone, we do NOT move it any way */
  const frozenZoneStart = new Date()
  const frozenZoneEnd = new Date(frozenZoneStart.valueOf() + frozenTime * 1000)
  const oldStartDate = new Date(operation.startDate)
  const oldEndDate = new Date(operation.endDate)
  if (
    !operation.mainOperationId &&
    frozenTime &&
    ((frozenZoneEnd > newStartDate && frozenZoneStart < newEndDate) ||
      (frozenZoneEnd > oldStartDate && frozenZoneStart < oldEndDate))
  ) {
    return operation
  }

  const capacityPlans = map(availabilityPlans, ({ availabilityPlan, ...others }) => ({
    ...nextState.capacityPlans[availabilityPlan],
    ...others,
  }))

  const configuration = {
    capacityPlans,
    gaps: resource.resourceGaps,
    holidays: nextState.holidays,
  }

  const gapStream = new Availability(configuration, newStartDate, forcedTimezone)

  let newGaps = convertToRanges(gapStream.read(newEndDate))
    .map(gap => gap.intersect(newRange))
    .filter(gap => !!gap)

  while (newGaps.length > 0 && newGaps[0].contains(newStartDate)) {
    const gap = newGaps.shift()
    newStartDate = new Date(gap.end.valueOf()) // Move it outside the gap
  }
  newRange = moment.range(newStartDate, newEndDate)

  newGaps = convertToRanges(gapStream.reset(newStartDate).read(newEndDate))
    .map(gap => gap.intersect(newRange))
    .filter(gap => !!gap)
  while (newGaps.length > 0 && newGaps[newGaps.length - 1].contains(newEndDate)) {
    const gap = newGaps.pop()
    newEndDate = new Date(gap.start.valueOf()) // Move it outside the gap
  }
  newRange = moment.range(newStartDate, newEndDate)

  newGaps = convertToRanges(gapStream.reset(newStartDate).read(newEndDate))
    .map(gap => gap.intersect(newRange))
    .filter(gap => !!gap)

  const planTime = newEndDate - newStartDate - sumUpGaps(newGaps)

  const updatedOperation = {
    ...operation,
    endDate: moment(newEndDate).format(),
    planTime: planTime / 1000,
    startDate: moment(newStartDate).format(),
  }

  return updatedOperation
}

const validateDependentOperation = (resource, allOperations, operation) => {
  if (operation.planTime <= 0) return { conflicts: [], errorCode: operationHasNoCapacity } // see next step

  const operationsOnThisResource = filter(
    allOperations,
    op =>
      op.productionOrderId !== operation.productionOrderId &&
      op.operationId !== operation.operationId &&
      op.resource === operation.resource &&
      new Date(op.startDate) < new Date(operation.endDate) &&
      new Date(op.endDate) > new Date(operation.startDate)
  )
  const conflicts = getResourceConflicts(operation.resource, [...operationsOnThisResource, operation], resource)
  return { conflicts, errorCode: null }
}

const getProjectOperations = (projectId, state) => filter(values(state.operations), op => op.projectId === projectId)

export const getPlanningObject = (state, operation) => {
  const { resource: resourceId, productionOrderId } = operation
  const productionOrder = state.productionOrders[productionOrderId]
  const activity = state.activities[productionOrder.activityId]
  const resource = state.resources[resourceId]
  return { productionOrder, activity, resource }
}

export const checkIfInFrozenZone = (state, orderedSelectedOperations) => {
  const inFrozenZone = some(orderedSelectedOperations, ({ id }) => {
    const op = state.operations[id]
    const { resource } = getPlanningObject(state, op)
    const { frozenTime } = resource
    const frozenZoneEnd = new Date(new Date().valueOf() + frozenTime * 1000)
    const frozenZoneStart = new Date()
    const startDate = new Date(op.startDate)
    const endDate = new Date(op.endDate)
    return frozenTime && frozenZoneEnd > startDate && frozenZoneStart < endDate
  })
  if (inFrozenZone) {
    throw new errors.UserError("pages.timeline.multipleMove.frozenTime")
  }
}

export const checkIfInSameProductionOrder = (state, orderedSelectedOperations) => {
  const uniqProdOrderIds = uniqBy(orderedSelectedOperations, ({ id: oid }) => state.operations[oid].productionOrderId)
  if (uniqProdOrderIds.length > 1) {
    throw new errors.UserError("pages.timeline.multipleMove.fromOneProductionOrder")
  }
}

const updateResourcesOnOperations = (suggestions, state) => {
  forEach(suggestions, ({ operation }) => {
    const cloned = clone(state.operations[operation.operationId])
    cloned.resource = operation.resource || cloned.resource // || - for security
    cloned.suggestResource = operation.resource
    // eslint-disable-next-line no-param-reassign
    state.operations[operation.operationId] = cloned
  })
  return state
}

export const newTimelineStateWithNew = (payload, state) => {
  const nextState = payload.suggestions
    ? updateResourcesOnOperations(payload.suggestions, cloneStore(state))
    : cloneStore(state)
  // Iterate over all selected items and handle their changes
  nextState.operationsResourcesSuggestions = {}
  return nextState
}

export const isOperationToBeAttended = (normalOperation, attendantOperation) => {
  const { startDate: start, endDate: end } = normalOperation
  const { startDate, endDate } = attendantOperation
  return new Date(start) < new Date(endDate) && new Date(end) > new Date(startDate)
}

const findOperationsToAttend = (operationsFromProductionOrder, attendantOperation) => {
  const operationsToAttend = filter(operationsFromProductionOrder, normalOperation =>
    isOperationToBeAttended(normalOperation, attendantOperation)
  )
  const operationsToAttendSorted = orderBy(
    operationsToAttend,
    [op => new Date(op.startDate)],
    [op => new Date(op.endDate)],
    ["asc", "asc"]
  )
  return [operationsToAttendSorted[0], last(operationsToAttendSorted)]
}

export const getAttendantOperations = (state, productionOrderId) => {
  const operationFromProductionOrderIds = state.productionOrders[productionOrderId]?.operations
  const operationFromProductionOrder = map(operationFromProductionOrderIds, id => state.operations[id])
  const operationsWithoutAttendant = filter(
    operationFromProductionOrder,
    ({ attendantOperation }) => !attendantOperation
  )
  const attendantOperations = keyBy(filter(operationFromProductionOrder, "attendantOperation"), "operationId")
  return mapValues(attendantOperations, ({ startDate, endDate }) => {
    let startOperation = find(operationsWithoutAttendant, ({ startDate: start }) => start === startDate)
    let endOperation = find(operationsWithoutAttendant, ({ endDate: end }) => end === endDate)
    if (!startOperation || !endOperation) {
      const [firstOperation, lastOperation] = findOperationsToAttend(operationsWithoutAttendant, {
        startDate,
        endDate,
      })
      startOperation = startOperation || firstOperation
      endOperation = endOperation || lastOperation
    }

    return [startOperation?.operationId, endOperation?.operationId]
  })
}

export const getAttendantOperationsFull = (state, productionOrderId) => {
  const operationFromProductionOrderIds = state.productionOrders[productionOrderId]?.operations
  const operationFromProductionOrder = map(operationFromProductionOrderIds, id => state.operations[id])
  return filter(operationFromProductionOrder, "attendantOperation")
}

export const changeAttendantOperations = (state, movedOperation, attendantOperations) => {
  const { operationId } = movedOperation
  const newStartDate = movedOperation.startDate
  const bufferTime = movedOperation.bufferTime || 0
  const newEndDate = moment(movedOperation.endDate).add(bufferTime, "s").format()
  return mapValues(attendantOperations, ([startOperationId, endOperationId], opId) => {
    const attendantOperation = clone(state.operations[opId])
    if (startOperationId === operationId) {
      attendantOperation.startDate = newStartDate
    }
    if (endOperationId === operationId) {
      attendantOperation.endDate = newEndDate
    }
    return attendantOperation
  })
}

export const writeAttendantOperations = (operations, changedAttendantOperations) => {
  forEach(changedAttendantOperations, attOperation => {
    // eslint-disable-next-line no-param-reassign
    operations[attOperation.operationId] = attOperation
  })
}
