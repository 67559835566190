import React from "react"

import { FormattedMessage } from "react-intl"
import { banfNumber } from "pages/Shared/Columns/SpecificColumns/BanfNumber"
import { TerminationNames } from "../../../types/TerminationTypes"
import { Column } from "../interfaces"
import { TerminationCell } from "../../../components/DataGrid/cellRenderers"
import {
  productionOrderNumber,
  articleNumber,
  articleName,
  articleAmount,
  articleGroupName,
  orderPositionNumber,
  locationName,
  startDate,
  endDate,
  processCustomerNumber,
  processCustomerName,
  statusId,
  planAnyway,
  createdByFullName,
  createdOn,
  orderDate,
  terminationDate,
  ownedByFullName,
  processNumber,
  processName,
  pending,
} from "./columns"

const ColumnsConfigProductionOrders: Column[] = [
  productionOrderNumber,
  articleNumber,
  articleName,
  articleGroupName,
  {
    id: "parentProcessItem.parentProcessItem.article.articleName",
    translationId: "pages.planningObjects.productionOrders.fields.orderPositionArticleName",
    accessor: "parentProcessItem.parentProcessItem.article.articleName",
    Header: <FormattedMessage id="pages.planningObjects.productionOrders.fields.orderPositionArticleName" />,
  },
  articleAmount,
  orderPositionNumber,
  locationName,
  startDate,
  endDate,
  {
    id: "termination.terminationName",
    translationId: "term.termination",
    accessor: "termination.terminationId",
    Cell: TerminationCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.termination" />,
    export: ({ value }: { value: string }) => ({ id: `type.termination.${TerminationNames[value]}` }),
  },
  processCustomerNumber,
  processCustomerName,
  statusId,
  pending,
  processNumber,
  processName,
  ownedByFullName,
  terminationDate,
  orderDate,
  createdOn,
  createdByFullName,
  planAnyway,
  banfNumber,
].filter(col => !!col)

export default ColumnsConfigProductionOrders
