import { find, keyBy, map, memoize, orderBy } from "lodash"
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range"

import { mergeOrderedTimeSlices } from "./mergeSlices"

const timeStringToObject = str => {
  const parts = str.split(":")
  return {
    hour: parseInt(parts[0], 10),
    minute: parseInt(parts[1], 10),
    second: parseInt(parts[2], 10),
    millisecond: 0,
  }
}

const beforeMidnight = timeStringToObject("23:59:59")
const midnight = timeStringToObject("00:00:00")

const getCapacitiesByDay = memoize(capacities => keyBy(capacities, "weekday"))
const getDateFromDay = (date, timezone, startOfDay) => {
  // <-- easy thing, date is a day, so give me the day in the correct timezone
  const d = moment.tz(date, timezone) // parse input in a specific time zone
  return (startOfDay ? d.startOf("day") : d.endOf("day")).toDate()
}

export const alignToLocalMidnight = memoize((time, timezone) => {
  if (typeof timezone === "undefined") {
    throw new Error("alignToLocalMidnight needs timezone in its parameters or it will return rubbish")
  }
  const d = moment(time).tz(timezone)
  return d.set(midnight).toDate()
})

const orderedCapacityPlans = (capacityPlans, timezone) =>
  orderBy(
    map(capacityPlans, capacityPlan => ({
      ...capacityPlan,
      validFrom: getDateFromDay(capacityPlan.validFrom, timezone, true),
      validTo: capacityPlan.validTo ? getDateFromDay(capacityPlan.validTo, timezone) : new Date("2801-01-01"), // Expected end Planeus lifetime
    })),
    ["validFrom", "validTo", "id"],
    ["desc", "asc"] // sort validTo ascending because the capacityPlan with the soonest validTo should be picked first
  )

const withTime = (date, timeObj) => {
  const d = moment(date)
  d.set(timeObj)
  return d
}

const haveDayNightShift = capacity => capacity && capacity.isActive && capacity.timeTo < capacity.timeFrom

export class CapacityStream {
  /* Generates capacity items for a give timerange and can continue to produce them for another given timeframe */
  constructor(capacityPlans, from, timezone = "UTC") {
    this.capacityPlans = orderedCapacityPlans(capacityPlans, timezone)
    this.timezone = timezone

    if (moment.isMoment(from) || from instanceof Date) {
      this.from = alignToLocalMidnight(from, timezone)
    } else {
      throw new Error("Capacities class requires a start point for its calculation")
    }
  }

  findCapacityPlanForDay = (thisDay, takeSunday) => {
    const capacityPlan = find(
      this.capacityPlans,
      ({ validFrom, validTo }) => validFrom <= thisDay && validTo >= thisDay
    )
    if (!capacityPlan) {
      return null
    }
    const capacityByDay = getCapacitiesByDay(capacityPlan.availabilities)
    if (!capacityByDay) {
      return null
    }
    const capacity = capacityByDay[takeSunday ? 0 : thisDay.day()]
    return capacity
  }

  read(until = moment(this.from).add(7 * 24, "h"), separateGaps) {
    /**
     * if sunday have the capacity over the night
     * we take this capacity to calculate the sunday for the next week
     * */
    const ranges = []
    const current = moment(alignToLocalMidnight(until, this.timezone)) // this is always midnight of the next day in the requested timezone
    current.tz(this.timezone) // damn moment looses timezone when being instantiated on a date object

    while (current >= this.from) {
      const thisDaysMidnight = current.clone()
      const nextMidnight = withTime(thisDaysMidnight, beforeMidnight)

      current.subtract(1, "d") // moment will respect daylight saving switches and jump by 23 or 25 hours accordingly

      const thisDayDate = thisDaysMidnight.toDate()

      const capacity = this.findCapacityPlanForDay(thisDaysMidnight)
      let nextCapacity = this.findCapacityPlanForDay(current)
      if (thisDaysMidnight.day() === 1) {
        if (nextCapacity && nextCapacity.timeTo < nextCapacity.timeFrom) {
          nextCapacity = {}
        }
        const temp = thisDaysMidnight.clone()
        temp.add(6, "d")
        // If Monday and Sunday from this week have nightshift we take this one
        const tempCapacity = this.findCapacityPlanForDay(temp) // current + 7 Day
        if (tempCapacity && tempCapacity.timeTo < tempCapacity.timeFrom) {
          nextCapacity = tempCapacity
        }
      }
      const currentCapacityHaveNightshift = haveDayNightShift(capacity)
      const nextCapacityHaveNightshift = haveDayNightShift(nextCapacity)
      let lastSunHaveNightshift = false
      let capFromLastSun = {}
      if (thisDaysMidnight.day() === 0) {
        const lastSun = thisDaysMidnight.clone()
        lastSun.add(7, "d")
        capFromLastSun = this.findCapacityPlanForDay(lastSun)
        lastSunHaveNightshift = haveDayNightShift(capFromLastSun)
      }
      if (!capacity) {
        // no capacity here, but there may be one in the past due to validTo configuration
        continue // eslint-disable-line
      }

      // |Monday----|----Tuesday| Day is disabled
      if (!capacity.isActive && !lastSunHaveNightshift && !nextCapacityHaveNightshift) {
        ranges.push({
          end: nextMidnight.toDate(),
          start: thisDayDate,
        })
        continue
      }

      // |Friday---[__|__]----Saturday| Saturday disabled, Friday Nightshift
      if (!capacity.isActive && !lastSunHaveNightshift && nextCapacityHaveNightshift) {
        ranges.push({
          end: nextMidnight.toDate(),
          start: withTime(thisDaysMidnight, timeStringToObject(nextCapacity.timeTo)).toDate(),
        })
        continue
      }

      // ---[__|__]---Thursday---[__|__]---Friday---[__|__]---
      if (thisDaysMidnight.day() !== 0 && nextCapacityHaveNightshift && currentCapacityHaveNightshift) {
        ranges.push({
          end: withTime(thisDaysMidnight, timeStringToObject(capacity.timeFrom)).toDate(),
          start: withTime(thisDaysMidnight, timeStringToObject(nextCapacity.timeTo)).toDate(),
        })
        continue
      }

      // normal case, capacity for today is activated
      const beginningOfAvailability = withTime(thisDaysMidnight, timeStringToObject(capacity.timeFrom))
      const endOfAvailability = withTime(thisDaysMidnight, timeStringToObject(capacity.timeTo))

      if (!lastSunHaveNightshift) {
        // |Monday---[____]---Monday|Tuesday
        if (!endOfAvailability.isSame(nextMidnight) && !endOfAvailability.isSame(thisDaysMidnight)) {
          ranges.push({
            end: nextMidnight.toDate(),
            start: endOfAvailability.toDate(),
          })
        }
        if (!beginningOfAvailability.isSame(thisDaysMidnight) && !nextCapacityHaveNightshift) {
          ranges.push({
            end: beginningOfAvailability.toDate(),
            start: thisDayDate,
          })
        }
        // ---[__|__]----Friday---[____]----|----
        if (!beginningOfAvailability.isSame(thisDaysMidnight) && nextCapacityHaveNightshift) {
          ranges.push({
            end: beginningOfAvailability.toDate(),
            start: withTime(thisDaysMidnight, timeStringToObject(nextCapacity.timeTo)).toDate(),
          })
        }
      }

      // Sunday cases
      if (lastSunHaveNightshift) {
        // |Sunday---[__|__]----Monday|
        if (!capacity.isActive || currentCapacityHaveNightshift) {
          ranges.push({
            end: withTime(thisDaysMidnight, timeStringToObject(capFromLastSun.timeFrom)).toDate(),
            start: thisDayDate,
          })
          continue
        }
        // ---[__|__]----Sunday---[__|__]----Monday , Sunday is disabled
        if ((!capacity.isActive || currentCapacityHaveNightshift) && nextCapacityHaveNightshift) {
          ranges.push({
            end: withTime(thisDaysMidnight, timeStringToObject(capFromLastSun.timeFrom)).toDate(),
            start: withTime(thisDaysMidnight, timeStringToObject(nextCapacity.timeTo)).toDate(),
          })
          continue
        }

        // ---|----Sunday---[____]----[__|__]----Monday
        ranges.push({
          end: withTime(thisDaysMidnight, timeStringToObject(capFromLastSun.timeFrom)).toDate(),
          start: endOfAvailability.toDate(),
        })
        ranges.push({
          end: beginningOfAvailability.toDate(),
          start: thisDayDate,
        })
      }
    }
    this.from = alignToLocalMidnight(until, this.timezone)
    return mergeOrderedTimeSlices(ranges.reverse(), separateGaps)
  }

  reset(from) {
    this.from = alignToLocalMidnight(from, this.timezone)
    return this
  }
}
