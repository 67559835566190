import React from "react"
import { FormattedMessage } from "react-intl"
import { get, mapValues, keys, map } from "lodash"
import { grey } from "@mui/material/colors"
import SettingsBackupRestore from "../Icons/SettingsBackupRestore"
import {
  CommentCell,
  DecimalCell,
  DateCell,
  DifferenceCell,
  DurationCell,
  EnumCell,
  IntegerCell,
  SimpleGridUser,
  getStyleById,
  IconCell,
  DateTimeCell,
  BooleanCell,
} from "./cellRenderers"
import {
  DateHeader,
  DateTimeHeader,
  NumberHeader,
  SelectHeader,
  SelectHeaderCustomValues,
  SelectHeaderMultipleCustomValuesFunc,
  SelectYesNot,
} from "./filterHeaders"
import {
  inRangeDateFilter,
  inRangeDateTimeFilter,
  numberFilter,
  allDecimalsNumberFilter,
  simpleEqualFilter,
  statusFilter,
} from "./filterMethods"
import StatusTypes, { StatusNames } from "../../types/StatusTypes"

export const articleNumberColumn = {
  accessor: "article.articleNumber",
  Header: <FormattedMessage id="term.articleNumber" />,
}
export const articleNameColumn = {
  accessor: "article.articleName",
  Header: <FormattedMessage id="term.article" />,
}

export const commentColumn = {
  accessor: "comment",
  Header: <FormattedMessage id="item.comment" />,
  Cell: CommentCell,
  style: { textAlign: "center" },
  disableFilters: true,
  disableSortBy: true,
}

export const commentColumnSearchable = {
  accessor: "comment",
  Header: <FormattedMessage id="item.comment" />,
  style: { textAlign: "center" },
}

export const attachmentsColumn = {
  accessor: "hasAttachment",
  Header: <FormattedMessage id="item.hasAttachments" />,
  Cell: BooleanCell,
  Filter: SelectYesNot,
  filter: simpleEqualFilter,
}

export const customerColumn = {
  accessor: row => (row.customer ? row.customer.customerName : <FormattedMessage id="common.unknown" />),
  Header: <FormattedMessage id="term.customer" />,
  id: "customer",
}

export const dateColumn = {
  Cell: DateCell,
  Filter: DateHeader,
  filter: inRangeDateFilter,
}

export const dateTimeColumn = {
  Cell: DateTimeCell,
  Filter: DateTimeHeader,
  filter: inRangeDateTimeFilter,
}

export const differencePercentageColumn = {
  accessor: "diffPerc",
  Cell: DifferenceCell,
  Filter: NumberHeader,
  filter: allDecimalsNumberFilter,
  style: { textAlign: "center" },
  Header: <FormattedMessage id="pages.reports.charts.labels.diffPerc" />,
  export: ({ value }) => Math.round(value),
}

export const durationColumn = {
  accessor: "duration",
  Cell: DurationCell,
  disableFilters: true,
  Header: <FormattedMessage id="common.duration" />,
  id: "duration",
  style: { textAlign: "right" },
}

const getDuration = (duration, durationUnit) => {
  switch (durationUnit) {
    default:
      return (duration || 0) / 60
    case "hours":
      return (duration || 0) / (60 * 60)
    case "days":
      return (duration || 0) / (60 * 60 * 24)
  }
}

export const durationByUnitColumn = (propertyName, durationUnit, formatMessage = () => null, withoutHeader) => {
  const column = {
    accessor: row => getDuration(row[propertyName], durationUnit),
    Cell: DecimalCell,
    Filter: NumberHeader,
    filter: numberFilter,
    id: propertyName,
    style: {
      textAlign: "right",
    },
  }
  if (!withoutHeader) {
    column.Header = `${formatMessage({ id: `pages.reports.charts.labels.${propertyName}` })} (${formatMessage({
      id: `type.time.${durationUnit}`,
    })})`
  }
  return column
}

export const integerColumn = {
  filter: numberFilter,
  Filter: NumberHeader,
  style: { textAlign: "right" },
  Cell: IntegerCell,
}

export const decimalColumn = {
  filter: numberFilter,
  Filter: NumberHeader,
  style: { textAlign: "right" },
  Cell: DecimalCell,
}

const progressTypes = {
  output: { title: "output", messageId: "term.output" },
  reject: { title: "reject", messageId: "term.reject" },
  time: { title: "time", messageId: "term.time" },
  setup: { title: "setup", messageId: "term.setup" },
  trouble: { title: "trouble", messageId: "term.trouble" },
}

export const requiredCapacityColumn = {
  ...decimalColumn,
  id: "requiredCapacity",
  accessor: row => row.requiredCapacity || 0,
  Header: <FormattedMessage id="term.requiredCapacity" />,
}

export const progressTypeColumn = {
  accessor: "type",
  Header: <FormattedMessage id="term.progressType" />,
  Cell: EnumCell(
    ({ value }) => <FormattedMessage id={progressTypes[value] ? progressTypes[value].messageId : "common.unknown"} />,
    () => ({
      backgroundColor: grey[400],
    })
  ),
  filter: simpleEqualFilter,
  Filter: SelectHeader(mapValues(progressTypes, ({ messageId }) => <FormattedMessage id={messageId} />)),
  export: ({ value }) => ({ id: progressTypes[value] ? progressTypes[value].messageId : "common.unknown" }),
}

export const progressAmountColumn = {
  ...integerColumn,
  id: "progressAmount",
  accessor: row => row.amount || 0,
  Header: <FormattedMessage id="item.amount" />,
}

export const progressTargetAmountColumn = {
  ...integerColumn,
  id: "targetAmount",
  accessor: row => row.targetAmount || 0,
  Header: <FormattedMessage id="term.targetAmount" />,
}

export const progressRejectTypeColumn = {
  id: "progressRejectType",
  Header: <FormattedMessage id="term.rejectType" />,
  accessor: row => (row.rejectType ? row.rejectType.name : ""),
}

export const projectColumn = {
  id: "project",
  accessor: row =>
    row.project ? (
      `${get(row, "project.projectNumber", "")}, ${get(row, "project.projectName", "")}`
    ) : (
      <FormattedMessage id="common.unknown" />
    ),
  Header: <FormattedMessage id="term.project" />,
}

export const projectNumberColumn = {
  accessor: "project.projectNumber",
  Header: <FormattedMessage id="term.projectNumber" />,
}
export const projectNameColumn = {
  accessor: "project.projectName",
  Header: <FormattedMessage id="term.projectName" />,
}

export const resourceNameColumn = {
  accessor: "resource.resourceName",
  filterable: true,
  Header: <FormattedMessage id="term.resource" />,
}

export const statusColumn = (accessor, excludeKey) => ({
  accessor: row => StatusNames[get(row, accessor)],
  Cell: EnumCell(
    ({ value }) => <FormattedMessage id={`type.status.${value}`} />,
    value => getStyleById(StatusTypes[value])
  ),
  filter: statusFilter,
  Filter: SelectHeaderMultipleCustomValuesFunc(
    map(keys(StatusNames), statusKey => ({
      label: `type.status.${StatusNames[statusKey]}`,
      value: statusKey,
    })),
    excludeKey
  ),
  id: accessor,
  export: ({ value }) => ({
    id: `type.status.${value}`,
  }),
})

export const switchedOffColumn = {
  Cell: IconCell(SettingsBackupRestore),
  Filter: SelectHeaderCustomValues([
    {
      label: "common.revoked",
      value: true,
    },
    {
      label: "common.notRevoked",
      value: false,
    },
  ]),
  id: "switchedOff",
  export: ({ value }) => ({ id: value ? "common.revoked" : null }),
}

export const troubleTypeColumn = {
  id: "troubleType",
  accessor: "troubleType.troubleTypeName",
  Header: <FormattedMessage id="term.troubleType" />,
}

export const userColumn = {
  id: "user",
  accessor: "user.fullName",
  Header: <FormattedMessage id="term.user" />,
  Cell: SimpleGridUser("user"),
}

export const valueAddColumn = {
  accessor: "valueAddName",
  filterable: true,
  Header: <FormattedMessage id="common.name" />,
}
