import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import withStyles from "@mui/styles/withStyles"
import { noop } from "lodash"
import Typography from "@mui/material/Typography"

import Spinner from "../Spinner"
import { useBaselineReadOnly } from "../../accessControl/useBaselineReadOnly"

const styles = theme => ({
  footer: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
})

const FormFooter = ({
  classes,
  className,
  error,
  errorId,
  errorValues,
  fetchError,
  mutationError,
  invalid,
  rootClassName,
  close,
  pristine,
  submitting,
  hideCancelButton,
  hideSaveButton,
  children,
  save,
  loading,
  saveLabelId,
  cancelLabelId,
}) => {
  const readOnly = useBaselineReadOnly()
  return (
    <div className={`${classes.footer} ${rootClassName}`}>
      <Grid container>
        {loading && <Spinner />}
        {mutationError && (
          <Typography color="error">
            <FormattedMessage id="errors.saving" />
          </Typography>
        )}
        {fetchError && (
          <Typography color="error">
            <FormattedMessage id="errors.API.somethingWentWrong" />
          </Typography>
        )}
        {error && !errorId && (
          <Grid
            item
            alignItems="flex-start"
            className={className}
            container
            direction="row"
            justifyContent="flex-start"
          >
            <Typography color="error">
              <FormattedMessage id={`errors.API.${error}`} values={errorValues} />
            </Typography>
          </Grid>
        )}
        {errorId && (
          <Grid
            item
            alignItems="flex-start"
            className={className}
            container
            direction="row"
            justifyContent="flex-start"
          >
            <Typography color="error">
              <FormattedMessage id={errorId} values={errorValues} />
            </Typography>
          </Grid>
        )}
        {children && (
          <Grid
            item
            alignItems="flex-start"
            className={className}
            container
            direction="row"
            justifyContent="flex-start"
          >
            {children}
          </Grid>
        )}
        <Grid item className={className} container direction="row" justifyContent="flex-end">
          {!hideSaveButton && !readOnly && (
            <Button
              color="primary"
              disabled={pristine || invalid || submitting || loading}
              data-test-id="footerSave"
              disableRipple
              type="submit"
              onClick={save}
            >
              <FormattedMessage id={saveLabelId} />
            </Button>
          )}
          {!hideCancelButton && (
            <Button data-test-id="footerClose" color="primary" onClick={close}>
              <FormattedMessage id={cancelLabelId} />
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

FormFooter.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorId: PropTypes.string,
  errorValues: PropTypes.object,
  fetchError: PropTypes.object,
  loading: PropTypes.bool,
  mutationError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  save: PropTypes.func,
  hideCancelButton: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  rootClassName: PropTypes.string,
  submitting: PropTypes.bool,
  saveLabelId: PropTypes.string,
  cancelLabelId: PropTypes.string,
}

FormFooter.defaultProps = {
  children: null,
  error: null,
  errorId: null,
  errorValues: null,
  fetchError: null,
  loading: false,
  mutationError: null,
  hideCancelButton: false,
  hideSaveButton: false,
  invalid: false,
  pristine: true,
  rootClassName: "",
  submitting: false,
  save: noop,
  saveLabelId: "action.save",
  cancelLabelId: "action.close",
}

export default withStyles(styles)(FormFooter)
