// Production Planning

import keyMirror from "../utils/keyMirror"

export const productionPlanningModules = keyMirror({
  splitorderposition: null,
  dashboard: null,
  planningObjects: null,
  planningObjectsreadonly: null,
  planning: null,
  planningreadonly: null,
  workbench: null,
  conflictRadar: null,
  conflictRadarreadonly: null,
  gaplist: null,
  basedata: null,
  basedatareadonly: null,
  reports: null,
  import: null,

  workbenchscan: null,
  articlepartslist: null,
})

export const planningObjectsTabs = keyMirror({
  projects: null,
  orders: null,
  productionOrders: null,
  operations: null,
  activities: null,
})

export const planningTabs = keyMirror({
  timeline: null,
  production: null,
})

export const workbenchTabs = keyMirror({
  workbenchactivities: null,
  workbenchoperations: null,
})

export const conflictRadarTabs = keyMirror({
  affectedoperations: null,
  conflicts: null,
  delayed: null,
})

export const baseDataTabs = keyMirror({
  articles: null,
  templates: null,
  technologies: null,
  technologyItems: null,
  resources: null,
  resourcepriority: null,
  customers: null,
  material: null,
})

export const reportingTabs = keyMirror({
  reportingplugins: null,
})

export const importTabs = keyMirror({
  automatedimport: null,
})

// Administration

export const administrationModules = keyMirror({
  userManagement: null,
  licences: null,
  workbenchterminal: null,
  globalsettings: null,
  backup: null,
})

export const userManagementTabs = keyMirror({
  userroles: null,
  users: null,
})

// Configuration

export const configurationModules = keyMirror({
  articleGroups: null,
  objectNames: null,
  units: null,
  resourceGroups: null,
  gaptypes: null,
  troubletypes: null,
  availabilityplans: null,
  rejecttypes: null,
  storageLocations: null,
  pools: null,
  locations: null,
  equipments: null,
})

// My Planeus

export const myPlaneusModules = keyMirror({
  userinfo: null,
  modulepreferences: null,
  languages: null,
})
