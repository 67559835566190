import React from "react"

import { FormattedMessage } from "react-intl"
import { Column } from "../interfaces"
import { statusColumn } from "../../../components/DataGrid/commonColumns"
import { BooleanCell, DateCell, DateTimeCell, DecimalCell, GridUser } from "../../../components/DataGrid/cellRenderers"
import {
  DateHeader,
  NumberHeader,
  SelectHeaderCustomValues,
  SelectYesNot,
} from "../../../components/DataGrid/filterHeaders"
import { exportBoolean } from "../../../components/DataGrid/export"
import { Message, User, getUsername } from "../../../components/UserName/UserName"

export const statusId: Column = {
  id: "statusId",
  translationId: "item.status",
  accessor: "statusId",
  Header: <FormattedMessage id="item.status" />,
  showTooltip: false,
  ...statusColumn("statusId"),
}

export const createdByFullName: Column = {
  id: "createdBy.fullName",
  translationId: "item.createdBy",
  accessor: "createdBy",
  Header: <FormattedMessage id="item.createdBy" />,
  Cell: GridUser,
  export: ({ value }: { value: User }, formatMessage: (arg0: Message) => string): string =>
    getUsername(value, formatMessage),
}

export const ownedByFullName: Column = {
  id: "ownedBy.fullName",
  translationId: "item.ownedBy",
  accessor: "ownedBy",
  Header: <FormattedMessage id="item.ownedBy" />,
  Cell: GridUser,
  export: ({ value }: { value: User }, formatMessage: (arg0: Message) => string): string =>
    getUsername(value, formatMessage),
}

export const locationName: Column = {
  id: "location.name",
  translationId: "term.location",
  accessor: "location.name",
  Header: <FormattedMessage id="term.location" />,
}

export const createdOn: Column = {
  id: "createdOn",
  translationId: "item.createdOn",
  accessor: "createdOn",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.createdOn" />,
}

export const startDate: Column = {
  id: "startDate",
  translationId: "item.start",
  accessor: "startDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.start" />,
}

export const startDateTime: Column = {
  id: "startDate",
  translationId: "item.start",
  accessor: "startDate",
  Cell: DateTimeCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.start" />,
}

export const endDate: Column = {
  id: "endDate",
  translationId: "item.end",
  accessor: "endDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.end" />,
}

export const endDateTime: Column = {
  id: "endDate",
  translationId: "item.end",
  accessor: "endDate",
  Cell: DateTimeCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.end" />,
}

export const terminationDate: Column = {
  id: "terminationDate",
  translationId: "term.deliveryDate",
  accessor: "terminationDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="term.deliveryDate" />,
}

export const orderDate: Column = {
  id: "orderDate",
  translationId: "item.orderDate",
  accessor: "orderDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.orderDate" />,
}

export const processNumber: Column = {
  id: "process.processNumber",
  translationId: "term.projectNumber",
  accessor: "process.processNumber",
  Header: <FormattedMessage id="term.projectNumber" />,
}

export const processName: Column = {
  id: "process.processName",
  translationId: "term.projectName",
  accessor: "process.processName",
  Header: <FormattedMessage id="term.projectName" />,
}

export const processCustomerNumber: Column = {
  id: "process.customer.customerNumber",
  translationId: "term.customerNumber",
  accessor: "process.customer.customerNumber",
  Header: <FormattedMessage id="term.customerNumber" />,
}

export const processCustomerName: Column = {
  id: "process.customer.customerName",
  translationId: "term.customerName",
  accessor: "process.customer.customerName",
  Header: <FormattedMessage id="term.customerName" />,
}

export const articleAmount: Column = {
  id: "articleAmount",
  translationId: "term.articleAmount",
  accessor: "articleAmount",
  Cell: DecimalCell,
  Filter: NumberHeader,
  Header: <FormattedMessage id="term.articleAmount" />,
  width: 50,
}

export const articleNumber: Column = {
  id: "article.articleNumber",
  translationId: "term.articleNumber",
  accessor: "article.articleNumber",
  Header: <FormattedMessage id="term.articleNumber" />,
}

export const articleName: Column = {
  id: "article.articleName",
  translationId: "term.articleName",
  accessor: "article.articleName",
  Header: <FormattedMessage id="term.articleName" />,
}

export const pending: Column = {
  id: "pending",
  translationId: "item.pending",
  accessor: (row: { pending: boolean }): boolean => !row.pending,
  Cell: BooleanCell,
  Filter: SelectHeaderCustomValues([
    { value: true, label: "pages.planningObjects.orders.list.pending.off" },
    { value: false, label: "pages.planningObjects.orders.list.pending.on" },
  ]),
  Header: <FormattedMessage id="item.pending" />,
  showTooltip: false,
}

export const planAnyway: Column = {
  id: "planAnyway",
  translationId: "term.planAnywayColumn",
  accessor: "planAnyway",
  Header: <FormattedMessage id="term.planAnywayColumn" />,
  Cell: BooleanCell,
  Filter: SelectYesNot,
  width: 40,
  export: exportBoolean,
  showTooltip: false,
}

export const orderPositionNumber: Column = {
  id: "orderPositionNumber",
  translationId: "term.orderPosition",
  accessor: "orderPositionNumber",
  Filter: NumberHeader,
  Header: <FormattedMessage id="term.orderPosition" />,
  width: 50,
}

export const productionOrderNumber: Column = {
  id: "productionOrderNumber",
  translationId: "term.productionOrderNumber",
  accessor: "productionOrderNumber",
  Header: <FormattedMessage id="term.productionOrderNumber" />,
}

export const articleGroupName: Column = {
  id: "article.articleGroup.articleGroupName",
  translationId: "term.articleGroup",
  accessor: "article.articleGroup.articleGroupName",
  Header: <FormattedMessage id="term.articleGroup" />,
}
