import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

const Duration = ({ duration }) =>
  duration && (
    <FormattedMessage
      id={`type.duration.${duration.unit}`}
      defaultMessage="{amount, number, duration} {amount, plural, one {Unit} other {Units}}"
      values={{ amount: duration.amount }}
    />
  )

Duration.propTypes = {
  duration: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.oneOf(["seconds", "minutes", "hours", "days", "weeks", "months", "years"]),
  }),
}

Duration.defaultProps = {
  duration: null,
}

export default Duration
