import reduceReducers from "reduce-reducers"
import undoable, { excludeAction } from "redux-undo"

import ActionTypes from "../../constants/ActionTypes"

import entities from "./entities"
import del from "./delete"
import fetching from "./fetch"
import filters from "./filters"
import selection from "./selection"
import settings from "./settings"
import productionOrders from "./productionOrders"
import update from "./update"
import operations from "./operations"
import activities from "./activities"
import version from "./version"

const actionsUpdate = [
  ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS,
  ActionTypes.TIMELINE_DATA_FETCH_FAILURE,
  ActionTypes.TIMELINE_DATA_FETCH_REQUEST,
  ActionTypes.TIMELINE_DATA_FETCH_SUCCESS,
  ActionTypes.TIMELINE_FILTER_CLEVER,
  ActionTypes.TIMELINE_FILTER_RESET,
  ActionTypes.UI_TIMELINE_PLANNING_DETAILS_PROJECT_NAVIGATE,
  ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ORDER_POSITION_NAVIGATE,
  ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ACTIVITY_NAVIGATE,
  ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_PRODUCTION_ORDER_NAVIGATE,
  ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_OPERATION_NAVIGATE,
  ActionTypes.UI_TIMELINE_TOGGLE_ACTIVITY_LOCK,
  ActionTypes.UI_TIMELINE_TOGGLE_OPERATION_LOCK,
  ActionTypes.TIMELINE_ITEM_CLONED,
  ActionTypes.ENTITIES_UPDATE_PERSISTS,
  ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW_CLEAR,
  ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW,
  "@@router/LOCATION_CHANGE", // I don't know how, but redux-undo also catch this action
  ActionTypes.TIMELINE_SELECTION_SET,
  ActionTypes.TIMELINE_SELECTION_CLEAR,
  ActionTypes.UI_TIMELINE_FORCERERENDER,
  ActionTypes.UI_TIMELINE_FORCERERENDER_PLANNING_CLEAR,
  ActionTypes.UI_TIMELINE_FORCERERENDER_PRODUCTION_CLEAR,
  ActionTypes.UI_TIMELINE_FORCERERENDER_RESOURCE_CLEAR,
  ActionTypes.TIMELINE_TRIGGER_RAW,
  ActionTypes.TIMELINE_UPDATE_PROJECTS,
]

export default undoable(
  reduceReducers(
    del,
    entities,
    fetching,
    filters,
    selection,
    settings,
    update,
    productionOrders,
    operations,
    activities,
    version
  ),
  {
    limit: 10,
    filter: excludeAction(actionsUpdate),
    ignoreInitialState: true,
    syncFilter: true,
  }
)
