import { filter, includes, map, uniq } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"
import ItemTypes from "../../types/TimelineItemTypes"

export default (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.TIMELINE_FILTER_CLEVER: {
      const { productionOrderId } = payload

      const allOperationsOfTheSameProductionOrder = filter(state.operations, {
        productionOrderId,
      })
      const allResourceGroupsRelevant = uniq(map(allOperationsOfTheSameProductionOrder, "resourceGroup"))
      const allOperationsOnRelevantResources = filter(state.operations, ({ resourceGroup }) =>
        includes(allResourceGroupsRelevant, resourceGroup)
      )
      const allConnectedProductionOrders = uniq(map(allOperationsOnRelevantResources, "productionOrderId"))

      return {
        ...state,
        filters: {
          clever: productionOrderId,
          productionOrders: allConnectedProductionOrders,
          resourceGroups: allResourceGroupsRelevant,
        },
        selection: [{ focus: 1, id: productionOrderId, type: ItemTypes.ProductionOrder }],
      }
    }
    case ActionTypes.TIMELINE_FILTER_RESET: {
      return {
        ...state,
        filters: initialState.filters,
      }
    }
    default:
      return state
  }
}
