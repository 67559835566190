import memoizeOne from "memoize-one"
import { includes, isPlainObject, get, round } from "lodash"
import moment from "moment"
import { NO_OF_DECIMALS } from "../../constants/consts"
import StatusTypes from "../../types/StatusTypes"

const createRegExp = memoizeOne(str => new RegExp(str, "i"))

const allDecimalsNumberFilterCalculation = rowValue => {
  return rowValue < 0 ? Math.round(Math.abs(rowValue)) * -1 : Math.round(Math.abs(rowValue))
}

export const filterWrapper = filterCallback => (rows, id, filterValue) =>
  rows.filter(row => filterCallback({ id, value: filterValue }, row.values))

export const simpleIncludeFilter = filterWrapper(({ id, value }, row) =>
  createRegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")).test(row[id] || null)
) /* string is sanitized; undefined will turn to string "undefined"! */

export const simpleEqualFilter = filterWrapper((filter, row) => {
  const { id, value } = filter
  const comparisonValue = isPlainObject(value) ? value.enumValue : value
  // eslint-disable-next-line
  return comparisonValue == -1 || get(row, id) == comparisonValue
})

export const inRangeDateFilter = filterWrapper(({ id, value: filter }, row) => {
  const { dateFrom, dateTo } = filter

  const rowDate = moment(row[id]).format("YYYY-MM-DD")
  if (!dateFrom && !dateTo) {
    return true
  }

  if (!dateFrom) {
    return new Date(rowDate) <= new Date(dateTo)
  }

  if (!dateTo) {
    return new Date(rowDate) >= new Date(dateFrom)
  }

  return new Date(rowDate) >= new Date(dateFrom) && new Date(rowDate) <= new Date(dateTo)
})

export const inRangeDateTimeFilter = filterWrapper(({ id, value: filter }, row) => {
  const { dateFrom, dateTo } = filter

  const rowDate = moment(row[id]).toDate()
  if (!dateFrom && !dateTo) {
    return true
  }

  if (!dateFrom) {
    return rowDate <= new Date(dateTo)
  }

  if (!dateTo) {
    return rowDate >= new Date(dateFrom)
  }

  return rowDate >= new Date(dateFrom) && rowDate <= new Date(dateTo)
})

export const numberFilter = filterWrapper(({ id, value }, row) => {
  if (!value) {
    return true
  }

  const rowValue = round(row[id], NO_OF_DECIMALS)
  const { to, comparator } = value
  if (to && comparator) {
    switch (value.comparator) {
      case "<":
        return rowValue < to
      case ">":
        return rowValue > to
      default:
        // eslint-disable-next-line
        return rowValue == to
    }
  }
  return true
})

export const allDecimalsNumberFilter = filterWrapper(({ id, value }, row) => {
  if (!value) {
    return true
  }

  const rowValue = row[id]
  const { to, comparator } = value
  if (to && comparator) {
    switch (value.comparator) {
      case "<":
        return allDecimalsNumberFilterCalculation(rowValue) < round(to)
      case ">":
        return allDecimalsNumberFilterCalculation(rowValue) > round(to)
      default:
        return allDecimalsNumberFilterCalculation(rowValue) === round(to)
    }
  }
  return true
})

export const translatedFilter = filterWrapper(({ id, value }, row) => createRegExp(value).test(row[`${id}_translated`]))

export const statusFilter = filterWrapper(({ id, value }, row) => {
  const { selectedValues } = value
  const rowStatus = row[id]

  if (selectedValues.length === 0) {
    return true
  }

  if (includes(selectedValues, String(StatusTypes.notDone))) {
    return rowStatus !== "done"
  }

  return includes(selectedValues, String(StatusTypes[rowStatus]))
})
