import keyMirror from "keymirror"
import accessProxy from "../utils/accessProxy"

export const API_CALL = kind => ({
  [kind]: null,
  [`${kind}_REQUEST`]: null,
  [`${kind}_DO_REQUEST`]: null,
  [`${kind}_SUCCESS`]: null,
  [`${kind}_FAILURE`]: null,
})

export const PAGES = kind => ({
  [`PAGES_${kind}`]: null,
  [`PAGES_${kind}_RESET`]: null,
})

const actionTypes = {
  REDUX_FORM_ARRAY_PUSH: "@@redux-form/ARRAY_PUSH",
  REDUX_FORM_ARRAY_REMOVE: "@@redux-form/ARRAY_REMOVE",
  REDUX_FORM_CHANGE: "@@redux-form/CHANGE",
  REDUX_FORM_INITIALIZE: "@@redux-form/INITIALIZE",
  REDUX_UNDO: "@@redux-undo/UNDO",

  ...keyMirror({
    APP_INIT: null,
    API_ERROR: null,
    RESET_STORE: null,
    APP_SIMULATION_SET: null,
    APP_SIMULATION_BASELINE_SET: null,
    APP_SIMULATION_RESET: null,

    SNACKBAR_MESSAGE_CREATE: null,
    SNACKBAR_MESSAGE_REMOVE: null,

    NAVIGATE_NEW_ARTICLE_DIALOG: null,
    NAVIGATE_ARTICLE_LIST: null,
    NAVIGATE_EDIT_ARTICLE_DIALOG: null,

    UI_ARTICLE_DIALOG_CHANGE_STANDARD_TECHNOLOGY: null,
    UI_ARTICLE_DIALOG_CHANGE_STANDARD_TEMPLATE: null,
    UI_ARTICLE_DIALOG_DELETED_TECHNOLOGY: null,
    UI_ARTICLE_DIALOG_DELETED_TEMPLATE: null,

    UI_ORDER_REINITIALIZE_ARTICLE_DEPENDENT_FIELDS: null,

    NORMALIZE_MIDDLEWARE_SAVE: null,

    ...PAGES("PROJECTS"),
    ...PAGES("ORDER_POSITIONS"),

    UI_BASEDATA_DETAILS_ARTICLE_NAVIGATE: null,
    UI_BASEDATA_DETAILS_CUSTOMER_NAVIGATE: null,
    UI_BASEDATA_DETAILS_RESOURCE_NAVIGATE: null,
    UI_BREADCRUMBS_SET: null,
    UI_BREADCRUMBS_CLEAR: null,
    UI_FILTERS_SET: null,
    UI_USER_FILTER_CHANGE: null,
    UI_PLANNING_OBJECTS_DETAILS_PROJECT_NAVIGATE: null,

    UI_TIMELINE_SHOW_NEXT_RANGE: null,
    UI_TIMELINE_SHOW_PREVIOUS_RANGE: null,
    UI_TIMELINE_SHOW_TODAY: null,
    UI_TIMELINE_SET_WINDOW_SIZE: null,
    UI_TIMELINE_TIMELINE_MOVED: null,
    UI_TIMELINE_RESET: null,
    UI_TIMELINE_TOGGLE_TOOLTIPS: null,
    UI_TIMELINE_CLOSE_PLANNING_DRAWER: null,
    UI_TIMELINE_CLOSE_PRODUCTION_DRAWER: null,
    UI_TIMELINE_DRAG_COLUMN_HEADER_WIDTH: null,
    UI_TIMELINE_PLANNING_DETAILS_PROJECT_NAVIGATE: null,
    UI_TIMELINE_PLANNING_DETAILS_ORDER_POSITION_NAVIGATE: null,
    UI_TIMELINE_PLANNING_DETAILS_ACTIVITY_NAVIGATE: null,

    UI_TIMELINE_PRODUCTION_DETAILS_PRODUCTION_ORDER_NAVIGATE: null,
    UI_TIMELINE_PRODUCTION_DETAILS_OPERATION_NAVIGATE: null,

    UI_TIMELINE_TIMELINE_SET_DEFAULT: null,

    UI_TIMELINE_TOGGLE_PERSONEL_PLAN: null,
    UI_TIMELINE_TOGGLE_PRODUCTION_TIMELINE: null,
    UI_TIMELINE_TOGGLE_RESOURCES_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWARCHIVEDSOURCES_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWDATEFILTER_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWDELAYEDCONFLICTS_TIMELINE: null,
    UI_TIMELINE_SET_SHOWDATEFILTER_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWFINISHED_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWFREERESOURCES_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWFINISHEDPROJECTS_TIMELINE: null,
    UI_TIMELINE_TOGGLE_SHOWVISIBLEAREA_TIMELINE: null,
    UI_TIMELINE_TOGGLE_ACTIVITY_LOCK: null,
    UI_TIMELINE_TOGGLE_OPERATION_LOCK: null,
    UI_TIMELINE_USER_FILTER_SET: null,

    UI_TIMELINE_FILTER_CLEAR: null,
    UI_TIMELINE_FILTER_UPDATE: null,
    TIMELINE_FILTER_FORM_SET_VALUES: null,

    UI_TIMELINE_FORCERERENDER_PLANNING_CLEAR: null,
    UI_TIMELINE_FORCERERENDER_PRODUCTION_CLEAR: null,
    UI_TIMELINE_FORCERERENDER_RESOURCE_CLEAR: null,
    UI_TIMELINE_FORCERERENDER: null,

    UI_WORKBENCH_WORKBENCH_SET_DEFAULT: null,
    UI_WORKBENCH_CHANGE_SHOWFINISHED_OPERATION: null,
    UI_WORKBENCH_CHANGE_SHOWPLAUSI_OPERATION: null,
    UI_WORKBENCH_CHANGE_SEARCH_OPERATION: null,
    UI_WORKBENCH_CHANGE_SHOWFINISHED_ACTIVITY: null,
    UI_WORKBENCH_CHANGE_SHOWPLAUSI_ACTIVITY: null,
    UI_WORKBENCH_CHANGE_SEARCH_ACTIVITY: null,
    UI_WORKBENCH_FILTER_CLEAR_OPERATION: null,
    UI_WORKBENCH_FILTER_CLEAR_ACTIVITY: null,
    UI_WORKBENCH_OPERATIONS_FILTER_SET: null,
    UI_WORKBENCH_ACTIVITIES_FILTER_SET: null,
    UI_WORKBENCH_PROGRESS_SETTINGS_HIDE_REGISTER_SET: null,
    WORKBENCH_FILTER_FORM_SET_VALUES: null,

    UI_AVAILABLE_HOURS_PER_DAY_FILTER_CLEAR: null,
    UI_AVAILABLE_HOURS_PER_DAY_FILTER_SET: null,
    UI_AVAILABLE_HOURS_PER_DAY_SET_VALUES: null,
    UI_AVAILABLE_HOURS_PER_DAY_USER_FILTER_SET: null,

    ...API_CALL("USER_LOGIN"),
    ...API_CALL("USER_LOGOUT"),
    ...API_CALL("IMPORTS_LIST"),
    ...API_CALL("IMPORTS_UPLOAD"),
    ...API_CALL("IMPORTS_VALIDATE"),
    ...API_CALL("IMPORTS_PROCESS"),

    USER_AUTO_LOGIN_FAILURE: null,
    USER_AUTO_LOGIN_SUCCESS: null,
    USER_AUTO_LOGIN_TERMINAL_SUCCESS: null,
    USER_AUTO_LOGIN_TERMINAL_FAILURE: null,
    USER_LOGIN_CHECK: null,
    USER_LOGIN_SUCCESS: null,
    USER_TERMINAL_LOGIN_SUCCESS: null,
    USER_TERMINAL_LOGIN_FAILURE: null,
    USER_LOGOUT_REQUEST: null,
    IMPORTS_BACK_TO_IMPORT: null,
    IMPORTS_CLEAR_FILE: null,
    IMPORTS_RESET: null,
    IMPORTS_TYPE_SELECT: null,

    PAGES_PROJECTS_RESET: null,
    PAGES_REPORT_PARAMETERS_SAVE: null,
    PAGES_REPORT_SETTINGS_SAVE: null,

    ...API_CALL("ACCESSIBLE_LICENCE_MODULES_LIST"),
    ADDITIONAL_PROPERTIES_SUCCESS: null,
    ...API_CALL("LICENCE_MODULES_LIST"),
    ...API_CALL("LICENCE_MODULES_DETAILS"),
    ...API_CALL("LICENCE_MODULES_ADD"),
    ...API_CALL("LICENCE_MODULES_DETAILS_UPDATE"),

    ...API_CALL("USER_LANGUAGES"),

    ...API_CALL("USERS_LIST"),
    ...API_CALL("USERS_DETAILS"),
    ...API_CALL("USERS_ADD"),
    ...API_CALL("USERS_DELETE"),
    ...API_CALL("USERS_UPDATE"),
    ...API_CALL("USERS_CHANGE_PASSWORD"),
    ...API_CALL("USER_CHANGE_LANGUAGE"),

    ...API_CALL("ROLES_LIST"),

    ...API_CALL("MODULES_LIST"),

    ...API_CALL("BASEDATA_CUSTOMERS_LIST"),

    ...API_CALL("BASEDATA_TEMPLATES_LIST"),
    ...API_CALL("BASEDATA_TEMPLATE_DETAILS_LIST"),
    ...API_CALL("BASEDATA_TEMPLATE_DETAILS_UPDATE"),

    ...API_CALL("BASEDATA_STATUS_LIST"),

    ...API_CALL("BASEDATA_TECHNOLOGIES_LIST"),
    ...API_CALL("BASEDATA_TECHNOLOGY_DETAILS_LOAD"),
    ...API_CALL("BASEDATA_TECHNOLOGY_DETAILS_UPDATE"),
    ...API_CALL("BASEDATA_ARTICLE_TEMPLATE_DETAILS_LIST"),

    ...API_CALL("BASEDATA_RESOURCES_LIST"),
    ...API_CALL("BASEDATA_RESOURCES_UPDATE"),
    ...API_CALL("BASEDATA_RESOURCE_DETAILS_LOAD"),
    ...API_CALL("BASEDATA_RESOURCE_DETAILS_CREATE"),
    ...API_CALL("BASEDATA_RESOURCE_DETAILS_UPDATE"),
    ...API_CALL("BASEDATA_PUBLIC_HOLIDAYS_LIST"),

    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_CREATE"),
    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_CHECK"),
    ...API_CALL("PLANNING_OBJECTS_TERMINATIONS_LIST"),
    ...API_CALL("PLANNING_OBJECTS_AMOUNT_UNITS_LIST"),

    ...API_CALL("PLANNING_OBJECTS_ACTIVITY_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_ACTIVITY_DETAILS_UPDATE"),

    ...API_CALL("PLANNING_OBJECTS_OPERATION_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_OPERATION_DETAILS_UPDATE"),

    ...API_CALL("PLANNING_OBJECTS_ORDER_POSITION_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_ORDER_POSITION_DETAILS_UPDATE"),
    ...API_CALL("PLANNING_OBJECTS_ORDER_POSITIONS_PLAN"),
    PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_SEQUENCE: null,
    PLANNING_OBJECTS_ORDER_POSITION_REMOVE: null,
    ...API_CALL("PLANNING_OBJECTS_ORDER_POSITIONS_REVOKE_PLAN"),
    PLANNING_OBJECTS_ORDER_POSITION_DETAILS_LOAD_RESET: null,

    ...API_CALL("PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE"),
    ...API_CALL("PLANNING_OBJECTS_PRODUCTION_ORDER_REVOKE"),

    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_LOAD"),
    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_UPDATE"),
    ...API_CALL("PLANNING_OBJECTS_PROJECT_DETAILS_REVOKE"),

    ...API_CALL("PLANNING_OBJECTS_DELETE"),

    ...API_CALL("DASHBOARD_ORDER_POSITIONS_LIST"),

    ...API_CALL("TIMELINE_DATA_FETCH"),

    ...API_CALL("REPORTS_FETCH"),

    GLOBALSETTINGS_FETCH_SUCCESS: null,

    ...API_CALL("PIN_LOGIN"),
    ...API_CALL("SCREEN_LOGIN"),
    SCREEN_SET_LANGUAGE: null,
    PIN_AUTO_LOGIN: null,
    SET_TERMINAL_ID: null,
    ...API_CALL("SCREEN_LOCK"),
    ...API_CALL("TERMINAL_LOCK"),
    ...API_CALL("LOGOUT_TO_TERMINAL"),

    TERMINAL_SET_SEARCH: null,
    TERMINAL_SET_SHOW_FINISHED: null,
    TERMINAL_SET_SHOW_NEXT_DAYS: null,
    TERMINAL_SET_SHOW_WORKER: null,
    TERMINAL_SET_SHOW_PLAUSI: null,
    TERMINAL_SET_REJECTTYPE: null,
    TERMINAL_SET_TROUBLETYPE: null,
    TERMINAL_SET_RESOURCE_TYPE: null,
    TERMINAL_SET_PROGRESS_DATA: null,

    TIMELINE_ITEM_CLONE: null,
    TIMELINE_ITEM_CLONED: null,
    TIMELINE_ITEM_UPDATE: null,
    TIMELINE_UNDO: null,
    TIMELINE_ACTIVITY_MOVE: null,
    TIMELINE_ACTIVITY_MOVE_PREVIEW: null,
    TIMELINE_ACTIVITY_MOVE_PREVIEW_CLEAR: null,
    TIMELINE_OPERATION_MOVE: null,
    TIMELINE_OPERATIONS_FREE_MOVE: null,
    TIMELINE_PARALLEL_OPERATION_MOVE: null,
    TIMELINE_OPERATION_MOVE_PREVIEW: null,
    TIMELINE_OPERATION_MOVE_PREVIEW_CLEAR: null,
    TIMELINE_PRODUCTIONORDER_MOVE: null,
    TIMELINE_PROJECT_MOVE: null,

    TIMELINE_ACTIVITY_RESIZE: null,
    TIMELINE_OPERATION_RESIZE: null,
    // TIMELINE_PRODUCTIONORDER_RESIZE: null,
    TIMELINE_PROJECT_RESIZE: null,

    TIMELINE_SELECTION_ADD: null,
    TIMELINE_SELECTION_CLEAR: null,
    TIMELINE_SELECTION_DIRECTION: null,
    TIMELINE_SELECTION_RANGE: null,
    TIMELINE_SELECTION_SET: null,
    TIMELINE_CLOSE_GAPS_LEFT: null,
    TIMELINE_CLOSE_GAPS_RIGHT: null,
    TIMELINE_OPTIMIZE_OPERATIONS_LEFT: null,
    TIMELINE_OPTIMIZE_OPERATIONS_RIGHT: null,
    TIMELINE_OPERATION_REPLAN: null,
    TIMELINE_FILTER_CLEVER: null,
    TIMELINE_FILTER_RESET: null,
    TIMELINE_TRIGGER_RAW: null,
    TIMELINE_DATA_FETCH_PROJECTS: null,
    TIMELINE_UPDATE_PROJECTS: null,
    TIMELINE_UPDATE_PROJECTS_SAVE_TO_PAST: null,
    TIMELINE_SET_LOADING: null,
    TIMELINE_UNSET_LOADING: null,
    TIMELINE_PRODUCTIONORDER_LOCKED: null,
    TIMELINE_VERSION_UPDATE: null,

    ENTITIES_UPDATE_PERSISTS: null,
    TIMELINE_ITEM_UNDO_UPDATE: null,

    ...API_CALL("FILE_UPLOAD"),
    FILE_UPLOAD_CLEAR_FILELIST: null,
    FILE_UPLOAD_REMOVE_FILE: null,

    BASEDATA_RESOURCEGAPS_FORM_DATEFROM_CHANGE: null,
    USERS_LICENSE_SET_DEFAULT: null,
    USERS_CHANGE_PASSWORD_RESET: null,
    USERS_LANGUAGE_UPDATE: null,
    SHOWARCHIVED_TOOGLE: null,
    SOCKET_RECEIVED_CONFLICTS_COUNT: null,
    SEND_WEBSOCKET_MESSAGE: null,
    USER_DETAILS_SELF_UPDATE: null,
    USER_FORM_ERROR_RESET: null,
    SET_STANDARD_MODE: null,
    SET_TERMINAL_MODE: null,
  }),
}

export default accessProxy(actionTypes)
