import React from "react"

import moment from "moment"
import { FormattedMessage } from "react-intl"
import { Column } from "../interfaces"
import { statusColumn } from "../../../components/DataGrid/commonColumns"
import { BooleanCell, GridUser, ReadableSecondsCell } from "../../../components/DataGrid/cellRenderers"
import { NumberHeader } from "../../../components/DataGrid/filterHeaders"
import { Message, User, getUsername } from "../../../components/UserName/UserName"
import {
  articleAmount,
  articleName,
  articleNumber,
  articleGroupName,
  createdByFullName,
  createdOn,
  endDateTime,
  locationName,
  orderDate,
  orderPositionNumber,
  pending,
  productionOrderNumber,
  processName,
  processNumber,
  processCustomerName,
  processCustomerNumber,
  startDateTime,
  terminationDate,
} from "./columns"

const ColumnsConfigOperations: Column[] = [
  productionOrderNumber,
  articleNumber,
  articleName,
  articleGroupName,
  orderPositionNumber,
  {
    id: "valueAdd.valueAddName",
    translationId: "term.operationName",
    accessor: "valueAdd.valueAddName",
    Header: <FormattedMessage id="term.operationName" />,
  },
  locationName,
  {
    id: "positionNumber",
    translationId: "pages.planningObjects.operations.fields.positionNumber",
    accessor: "positionNumber",
    Filter: NumberHeader,
    Header: <FormattedMessage id="pages.planningObjects.operations.fields.positionNumber" />,
    width: 50,
  },
  {
    id: "setupTime",
    translationId: "term.setupTime",
    accessor: "setupTime",
    Cell: ReadableSecondsCell,
    disableFilters: true,
    Header: <FormattedMessage id="term.setupTime" />,
    width: 60,
  },
  articleAmount,
  {
    id: "duration",
    translationId: "term.duration",
    accessor: "duration",
    Cell: ReadableSecondsCell,
    Header: <FormattedMessage id="term.duration" />,
    width: 50,
    disableFilters: true,
  },
  {
    id: "calculatedDurationHours",
    translationId: "term.specificationTimeInHours",
    accessor: (row: { setupTime: number; duration: number; articleAmount: number; lotSize: number }): number => {
      const calculatedDuration = (row.setupTime || 0) + ((row.duration || 0) * row.articleAmount) / (row.lotSize || 1)
      return Math.floor(moment.duration(calculatedDuration, "seconds").asHours())
    },
    Filter: NumberHeader,
    Header: <FormattedMessage id="term.specificationTimeInHours" />,
    width: 40,
  },
  {
    id: "resourceGroup.resourceGroupName",
    translationId: "term.resourceGroup",
    accessor: "resourceGroup.resourceGroupName",
    Header: <FormattedMessage id="term.resourceGroup" />,
  },
  {
    id: "resource.resourceName",
    translationId: "term.resource",
    accessor: "resource.resourceName",
    Header: <FormattedMessage id="term.resource" />,
  },
  processCustomerNumber,
  processCustomerName,
  {
    id: "worker.fullName",
    translationId: "term.worker",
    accessor: "worker",
    Header: <FormattedMessage id="term.worker" />,
    Cell: GridUser,
    export: ({ value }: { value: User }, formatMessage: (arg0: Message) => string): string =>
      getUsername(value, formatMessage),
  },
  {
    id: "status.statusId",
    translationId: "item.status",
    accessor: "status.statusId",
    Header: <FormattedMessage id="item.status" />,
    ...statusColumn("status.statusId"),
    showTooltip: false,
  },
  pending,
  {
    id: "hasDescription",
    translationId: "common.description",
    accessor: "hasDescription",
    Header: <FormattedMessage id="common.description" />,
    Cell: BooleanCell,
    disableFilters: true,
    disableSortBy: true,
    showTooltip: false,
  },
  processNumber,
  processName,
  terminationDate,
  orderDate,
  createdOn,
  createdByFullName,
  startDateTime,
  endDateTime,
]

export default ColumnsConfigOperations
