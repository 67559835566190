import React from "react"

import { FormattedMessage } from "react-intl"
import { Column } from "../interfaces"
import { NumberHeader } from "../../../components/DataGrid/filterHeaders"
import {
  articleNumber,
  articleName,
  articleGroupName,
  orderPositionNumber,
  locationName,
  processCustomerNumber,
  processCustomerName,
  statusId,
  createdByFullName,
  createdOn,
  orderDate,
  terminationDate,
  ownedByFullName,
  processName,
  processNumber,
  pending,
  startDateTime,
  endDateTime,
} from "./columns"

const ColumnsConfigActivities: Column[] = [
  articleNumber,
  articleName,
  articleGroupName,
  {
    id: "positionNumber",
    translationId: "term.positionNumber",
    accessor: "positionNumber",
    Filter: NumberHeader,
    Header: <FormattedMessage id="term.positionNumber" />,
    width: 50,
  },
  orderPositionNumber,
  {
    id: "valueAdd.valueAddName",
    translationId: "term.activityType",
    accessor: "valueAdd.valueAddName",
    Header: <FormattedMessage id="term.activityType" />,
  },
  locationName,
  startDateTime,
  endDateTime,
  processCustomerNumber,
  processCustomerName,
  statusId,
  pending,
  processNumber,
  processName,
  ownedByFullName,
  terminationDate,
  orderDate,
  createdOn,
  createdByFullName,
]

export default ColumnsConfigActivities
