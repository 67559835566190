export const formatDuration = (duration, formatMessage) =>
  duration &&
  formatMessage(
    {
      id: `type.duration.${duration.unit}`,
      defaultMessage: "{amount, number, duration} {amount, plural, one {Unit} other {Units}}",
    },
    { amount: duration.amount }
  )
export const formatDurationShort = (duration, formatMessage) =>
  duration &&
  formatMessage(
    {
      id: `type.duration.short.${duration.unit}`,
      defaultMessage: "{amount, number, duration} {amount, plural, one {Unit} other {Units}}",
    },
    { amount: duration.amount }
  )
