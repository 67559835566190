import { groupBy, keyBy, mapValues } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"
import {
  setConflictsByOrderPosition,
  setExactDeliveryDateOfOrderPositions,
  setOperationConflictsByStatus,
  getResourceConflicts,
} from "./helpers/conflicts"

const readIntoStore = payload => {
  const {
    activities,
    articles,
    availabilityPlans,
    customers,
    operations,
    orderPositions,
    productionOrders,
    projects,
    publicHolidays,
    resourceGroups,
    resources,
    users,
    valueAdds,
  } = payload

  const userMap = keyBy(users, "userId")
  const resourcesGrouped = keyBy(resources, "resourceId")
  const state = {
    activities: keyBy(activities, "activityId"),
    articles: keyBy(articles, "articleId"),
    capacityPlans: keyBy(availabilityPlans, "availabilityPlanId"),
    customers: keyBy(customers, "customerId"),
    holidays: publicHolidays,
    operations: keyBy(operations, "operationId"),
    orderPositions: keyBy(orderPositions, "orderPositionId"),
    productionOrders: keyBy(productionOrders, "productionOrderId"),
    projects: keyBy(projects, "projectId"),
    resourceGroups: keyBy(resourceGroups, "resourceGroupId"),
    resources: resourcesGrouped,
    valueAdds: keyBy(valueAdds, "valueAddId"),
    conflicts: {
      datesMissedByPosition: {},
      operationsDelayed: {},
      operationsByResource: mapValues(groupBy(operations, "resource"), (itemsWithStringDates, resource) =>
        getResourceConflicts("operationId", itemsWithStringDates, resourcesGrouped[resource])
      ),
    },
  }

  setExactDeliveryDateOfOrderPositions(state)
  setConflictsByOrderPosition(state)
  setOperationConflictsByStatus(state)
  const addOwner = project => {
    const user = userMap[project.ownerId] || {}
    return {
      ...project,
      owner: user ? `${user.firstName} ${user.lastName}` : "unknown",
    }
  }
  state.projects = mapValues(state.projects, addOwner)
  state.orderPositions = mapValues(state.orderPositions, addOwner)
  state.productionOrders = mapValues(state.productionOrders, addOwner)

  return state
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TIMELINE_DATA_FETCH_FAILURE: {
      return {
        ...state,
        status: {
          isPending: false,
          isLoaded: false,
        },
      }
    }
    case ActionTypes.TIMELINE_DATA_FETCH_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          isPending: true,
        },
      }
    }
    case ActionTypes.TIMELINE_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        ...readIntoStore(action.payload, state),
        status: {
          ...state.status,
          isPending: false,
          isLoaded: true,
        },
      }
    }

    default:
      return state
  }
}
