import React, { useState } from "react"
import PropTypes from "prop-types"
import Tooltip from "@mui/material/Tooltip"
import withStyles from "@mui/styles/withStyles"
import useToggle from "@rooks/use-toggle"

const styles = () => ({
  wrap: {
    position: "relative",
    width: "100%",
    cursor: "inherit",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  multiline2: {
    width: "100%",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10001,
    fontFamily: "Roboto",
    pointerEvents: "none",
  },
  popper: {
    zIndex: 10001,
    pointerEvents: "none",
  },
})

const getPosition = (ele, positionStyle) => {
  const element = ele.getBoundingClientRect()
  const { width, height, left, top } = element
  const offsetTop = document.body.getBoundingClientRect().top
  return {
    position: positionStyle,
    pointerEvents: "none",
    width,
    height,
    left,
    top: top - offsetTop,
  }
}

const TooltipOverflow = props => {
  const {
    always,
    classes,
    tooltip,
    children,
    textAlign,
    className,
    multilineInTwo,
    showTooltipProp,
    positionStyle,
    ...tooltipProps
  } = props
  const [isOpen, switchIsOpen] = useToggle(false)
  const [proxyStyle, setProxyStyle] = useState({})
  const [textWrap, setTextWrap] = useState(null)

  const shouldOpenTooltip = () => always || textWrap.offsetWidth < textWrap.scrollWidth

  const showTooltip = () => {
    if (showTooltipProp && shouldOpenTooltip() !== isOpen) {
      switchIsOpen()
      setProxyStyle(getPosition(textWrap, positionStyle))
    }
  }

  const hideTooltip = () => {
    if (isOpen) {
      switchIsOpen()
    }
    setProxyStyle({})
  }

  const title = tooltip || children

  return (
    <div
      ref={div => setTextWrap(div)}
      className={`${multilineInTwo ? classes.multiline2 : classes.wrap} ${className}`}
      onMouseEnter={() => {
        showTooltip()
      }}
      onMouseLeave={() => {
        hideTooltip()
      }}
      style={{ textAlign }}
    >
      {children}
      {isOpen && (
        <Tooltip
          classes={{ popper: classes.popper }}
          open
          disableHoverListener
          title={title}
          placement="top-start"
          PopperProps={{ anchorEl: textWrap }}
          {...tooltipProps}
        >
          <div className="tooltip-content" style={proxyStyle} />
        </Tooltip>
      )}
    </div>
  )
}

TooltipOverflow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  textAlign: PropTypes.string,
  always: PropTypes.bool,
  multilineInTwo: PropTypes.bool,
  showTooltipProp: PropTypes.bool,
  positionStyle: PropTypes.string,
}

TooltipOverflow.defaultProps = {
  className: "",
  tooltip: "",
  textAlign: "",
  always: false,
  multilineInTwo: false,
  showTooltipProp: true,
  positionStyle: "absolute",
}

export default withStyles(styles)(TooltipOverflow)
